// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

// this file is only here because styled-components injects styles differently than emotion
// it puts the styles in document -> head, and @font-face only works when you put them there
// in emotion, the styles get injected inside the shadow root, and there @font-face doesn't work
//
// I'm choosing not to migrate this, because we probably want to migrate away from this setup
// for web components and use the new saved-trips-component setup using Vite instead.

const GlobalStyles = createGlobalStyle`
  share-component, book-component, book-component-type-1, book-component-type-2, saved-trips-component {
      --color-black: #000;
      --color-white: #fff;
      --color-primary-5: #241E3D;
      --color-primary-20: #302A47;
      --color-primary-30: #413A5C;
      --color-primary-35: #544A79;
      --color-primary-40: #685B97;
      --color-primary-50: #7466A8;
      --color-primary-55: #7A6DA7;
      --color-primary-60: #8578B2;
      --color-primary-70: #B1A8D3;
      --color-primary-80: #C5BEDE;
      --color-primary-90: #D7D3E8;
      --color-secondary-0: var(--color-black);
      --color-secondary-40: #1A1726;
      --color-secondary-50: #34323D;
      --color-secondary-60: #54535B;
      --color-secondary-70: #86848E;
      --color-secondary-80: #B4B2BB;
      --color-secondary-85: #C7C5CC;
      --color-secondary-90: #DAD9DE;
      --color-secondary-93: #E3E1E9;
      --color-secondary-95: #EFEEF3;
      --color-secondary-97: #F7F6FC;
      --color-secondary-98: #FAFAFD;
      --color-tertiary-30: #F5E400;
      --color-tertiary-40: #FFED00;
      --color-tertiary-50: #FFF466;
      --color-tertiary-60: #FFF9A6;
      --color-quaternary-5: #3C3A35;
      --color-quaternary-30: #D8CEBC;
      --color-quaternary-40: #F0E6D4;
      --color-quaternary-50: #F3EFE6;
      --color-positive-70: #C9EDC2;
      --color-error-40: #D9001A;
      --font-family-primary: 'COCOGOOSE', sans-serif;
      --font-family-primary-light: 'Cocogoose Pro Light', sans-serif;
      --font-family-primary-semilight: 'Cocogoose Pro Semilight', sans-serif;
      --font-family-primary-bold: 'Cocogoose Pro Darkmode', sans-serif;
      --line-height-12: 0.75rem;
      --line-height-14: 0.875rem;
      --line-height-16: 1rem;
      --line-height-18: 1.125rem;
      --line-height-20: 1.25rem;
      --line-height-22: 1.375rem;
      --line-height-24: 1.5rem;
      --line-height-26: 1.625rem;
      --line-height-28: 1.75rem;
      --line-height-30: 1.875rem;
      --line-height-36: 2.25rem;
      --line-height-38: 2.375rem;
      --line-height-52: 3.250rem;
      --font-size-12: 0.75rem;
      --font-size-14: 0.875rem;
      --font-size-16: 1rem;
      --font-size-18: 1.125rem;
      --font-size-20: 1.25rem;
      --font-size-22: 1.375rem;
      --font-size-28: 1.75rem;
      --font-size-32: 2rem;
      --font-size-42: 2.625rem;
      --spacing-2: 0.125rem;
      --spacing-4: 0.25rem;
      --spacing-8: 0.5rem;
      --spacing-12: 0.75rem;
      --spacing-16: 1rem;
      --spacing-24: 1.5rem;
      --spacing-32: 2rem;
      --spacing-40: 2.5rem;
      --spacing-48: 3rem;
      --spacing-64: 4rem;
      --spacing-80: 5rem;
      --spacing-96: 6rem;
      --spacing-128: 8rem;
      --spacing-160: 10rem;
      --letter-spacing: -0.16%;
      --icon-size-s: var(--spacing-16);
      --icon-size-m: var(--spacing-24);
      --icon-size-l: var(--spacing-32);
      --font-link-s: var(--font-size-12) / var(--line-height-12) var(--font-family-primary-light);
      --font-link-l: var(--font-size-14) / var(--line-height-18) var(--font-family-primary-light);
      --font-link-xl: var(--font-size-16) / var(--line-height-24) var(--font-family-primary-light);
      --font-preamble-l: var(--font-size-20) / var(--line-height-26) var(--font-family-primary-semilight);
      --font-preamble-s: var(--font-size-16) / var(--line-height-22) var(--font-family-primary-semilight);
      --font-body-s-bold: var(--font-size-12) / var(--line-height-16) var(--font-family-primary-bold);
      --font-body-s-light: var(--font-size-12) / var(--line-height-16) var(--font-family-primary-light);
      --font-body-m-bold: var(--font-size-14) / var(--line-height-18) var(--font-family-primary-bold);
      --font-body-m-light: var(--font-size-14) / var(--line-height-18) var(--font-family-primary-light);
      --font-body-l-bold: var(--font-size-16) / var(--line-height-24) var(--font-family-primary-bold);
      --font-body-l-light: var(--font-size-16) / var(--line-height-24) var(--font-family-primary-light);
      --font-body-xl-bold: var(--font-size-16) / var(--line-height-24) var(--font-family-primary-bold);
      --font-body-xl-light: var(--font-size-18) / var(--line-height-30) var(--font-family-primary-light);
      --font-label-s-bold: var(--font-size-12) / var(--line-height-12) var(--font-family-primary-bold);
      --font-label-s-light: var(--font-size-12) / var(--line-height-12) var(--font-family-primary-light);
      --font-label-l-bold: var(--font-size-14) / var(--line-height-14) var(--font-family-primary-bold);
      --font-label-l-light: var(--font-size-14) / var(--line-height-14) var(--font-family-primary-light);
      --font-label-xl-bold: var(--font-size-16) / var(--line-height-16) var(--font-family-primary-bold);
      --font-label-xl-light: var(--font-size-16) / var(--line-height-16) var(--font-family-primary-light);
      --font-label-xxl-bold: var(--font-size-20) / var(--line-height-20) var(--font-family-primary-bold);
      --font-label-xxl-light: var(--font-size-20) / var(--line-height-20) var(--font-family-primary-light);
      --font-heading-5: var(--font-size-18) / var(--line-height-24) var(--font-family-primary-bold);
      --font-heading-4: var(--font-size-22) / var(--line-height-28) var(--font-family-primary-bold);
      --font-heading-3: var(--font-size-28) / var(--line-height-36) var(--font-family-primary-bold);
      --font-heading-2: var(--font-size-32) / var(--line-height-38) var(--font-family-primary-bold);
      --font-heading-1: var(--font-size-42) / var(--line-height-52) var(--font-family-primary-bold);
      --font-caption-l-light: var(--font-size-12)/ var(--line-height-16) var(--font-family-primary-light);
      --font-caption-l-bold: var(--font-size-12)/ var(--line-height-16) var(--font-family-primary-bold);
      --border-radius-s: 0.25rem;
      --border-radius-m: 0.5rem;
      --border-radius-l: 0.75rem;
      --border-radius-xl: 1rem;
      --border-radius-xxl: 2rem;
  }

  @font-face {
    font-family: 'Cocogoose Pro Darkmode';
    src: url('https://cdn.sanity.io/files/inntox6v/production/cb0acd973f40aa4e72252f6e16cf1a6c6b9ee82b.woff2/CocogoosePro-Darkmode.woff2') format('woff2'),
    url('https://cdn.sanity.io/files/inntox6v/production/ad8f81bae1e42af558a7b1e49fa5e7579dad8db1.woff/CocogoosePro-Darkmode.woff') format('woff'),
    url('https://cdn.sanity.io/files/inntox6v/production/bdc647dbedd727ad2ca8c267e51d355949e79392.svg/CocogoosePro-Darkmode.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro Semilight';
    src: url('https://cdn.sanity.io/files/inntox6v/production/0fd426dd95f586f6fa51b810d9c4ca060d88f834.woff2/CocogoosePro-Semilight.woff2') format('woff2'),
    url('https://cdn.sanity.io/files/inntox6v/production/0303fab577cc7c8f971f9fd31839d940fa01f0a2.woff/CocogoosePro-Semilight.woff') format('woff'),
    url('https://cdn.sanity.io/files/inntox6v/production/522d4793d87cb2090f19ffeeef2b476df25c8466.svg/CocogoosePro-Semilight.svg') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro Light';
    src: url('https://cdn.sanity.io/files/inntox6v/production/f8a2e25e3698e50e3590f79ab0eaf8dddd28bbf7.woff2/CocogoosePro-Light.woff2') format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/251d15dbecae5edb1cf8b985c34d88ddaa0aa5e0.woff/CocogoosePro-Light.woff') format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/537d48520a7e903511c43223d9e2aae0b0e994d6.svg/CocogoosePro-Light.svg')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cocogoose Pro';
    src: url('https://cdn.sanity.io/files/inntox6v/production/3d236b80db73f13f4a20fa13762755ec34f10c84.woff2/CocogoosePro-Regular.woff2') format('woff2'),
      url('https://cdn.sanity.io/files/inntox6v/production/0970c760da86f4c9428770e3b6cc8b2a4b093f5e.woff/CocogoosePro-Regular.woff') format('woff'),
      url('https://cdn.sanity.io/files/inntox6v/production/84a84da87920cc07a66183e0982a8dbb45280aa0.svg/CocogoosePro-Regular.svg')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;
export default GlobalStyles;
